import React from 'react';
import { graphql } from 'gatsby';

const RenderText = (props) => {
  const { data } = props
  return data.wordpressWpSettings.buildDate;
}
export default RenderText;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      buildDate
    }
  }
`
